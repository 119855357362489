<template>
  <div class="flex justify-center">

    <div class="flex justify-between mt-40 flex-col">

      <h2 class="font-bold text-center text-3xl pb-12">Find Providers</h2>
      <!-- <p class="text-center">The features you're trying to access are currently
        under construction and will be available soon.</p> <br> <br>
      <p class="text-center">We sincerely appreciate your patience.</p> -->
      <div class="grid grid-cols-2 gap-6">

        <div
          v-for="(item, index) in jobPosts"
          :key="index"
          @click="goToRoute(item)"
          class="border border-lightgrey rounded-lg flex justify-center items-center flex-col p-10 cursor-pointer"
        >
          <img :src="item.img" alt="" />
          <p class="text-center font-semibold text-lg pt-3">{{ item.text }}</p>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      jobPosts: [
        {
          img: require('../assets/img/database.svg'),
          text: 'Search our Database',
          link: 'booking'
        },
        {
          img: require('../assets/img/jobpost.svg'),
          text: 'Post Job Opportunity',
          link: 'hiring'
        },
      ],
    }
  },
  methods: {
    goToRoute(item) {
      this.$router.push({ name: item.link });
      sessionStorage.setItem("dateRange",'[]');
      sessionStorage.setItem("back",'')
    }
  }
}
</script>

<style>

</style>